/**
 * External Dependencies
 */
import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'

/**
 * Internal Dependencies
 */
import ArchiveFeatures from '../components/ArchiveFeatures'
import ArticleHeader from '../components/ArticleHeader'
import Layout from '../components/Layout'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import PromoPanelWide from '../components/PromoPanelWide'
import parseContent from '../utils/parse-content'
import { contentTypeFromPostNodeType, translatePostUrl } from '../utils/url-translations'
import { getContentTypeListingUrlTranslation, getContentTypeSlugUrlTranslation } from '../utils/url-translations'

const NUM_COLUMNS = 2

const ListingPage = context => {
  const {
    data,
    pageContext: {
      localeSettings,
      urlTranslations,
      pageUrl,
      pagedArticles,
      pagination,
      widePromoPanels,
      widePromoPanelSettings,
    },
  } = context

  let posts = pagedArticles
  let morePosts = []
  let showWidePanel = false

  posts = posts.filter(item => item !== null)
  posts = posts.map(item => {
    // translate each posts uri
    const postType = contentTypeFromPostNodeType(item.nodeType)
    const translatedSlug = getContentTypeSlugUrlTranslation(postType, urlTranslations.contentUrlTranslations)
    const urlTranslatedItem = translatePostUrl(item, postType, translatedSlug)
    return urlTranslatedItem
  })

  if (
    widePromoPanelSettings.length &&
    widePromoPanelSettings[0].id !== null &&
    widePromoPanelSettings[0].position !== null
  ) {
    // + 1 because offset is off by one
    const widePanelPosition = parseInt(widePromoPanelSettings[0].position, 10) + 1

    if (widePanelPosition !== undefined && widePanelPosition !== '') {
      // Split the paged Articles so we can insert the widePromoPanel
      // Only do this on the first page
      if (pagination.current === 1) {
        showWidePanel = true

        const startIndex = 0
        const finishIndex = NUM_COLUMNS * widePanelPosition

        posts = posts.slice(startIndex, finishIndex)
        morePosts = posts.slice(finishIndex)
      }
    }
  }

  let title = ''
  let headline = ''
  let subheadline = ''

  if (data.post?.title) {
    // If wordpress post, take content from there
    title = data.post.title
    headline = null
    subheadline = null
  } else {
    // pull in from YAML
    let {
      inspireArchive: { headline, subheadline },
    } = localeSettings

    title = parse(headline)
  }

  return (
    <Layout isHomePage settings={localeSettings}>
      <SEO title={title} seo={data.post?.seo} />

      {headline && (
        <ArticleHeader
          backgroundColor="#350C13"
          headline={<h1>{headline}</h1>}
          subheadline={parse(subheadline)}
        />
      )}
      {!headline && parseContent(data.post.content)}

      <div className="container alignfull">
        <ArchiveFeatures posts={posts} />
        {showWidePanel && (
          <div>
            <div className="component">
              <PromoPanelWide posts={widePromoPanels} />
            </div>
            <ArchiveFeatures posts={morePosts} />
          </div>
        )}

        <Pagination
          url={pageUrl}
          current={pagination.current}
          pages={pagination.pages}
          total={pagination.total}
        />
      </div>
    </Layout>
  )
}

export default ListingPage

export const pageQuery = graphql`
  query ListingPage($currentLocale: String!, $databaseId: Int!) {
    post: wpPage(
      databaseId: { eq: $databaseId },
      language: {
        locale: { eq: $currentLocale }
      }
    ) {
       id
       databaseId
       uri
       slug
       guid
       title
       content
       seo {
         metaDesc
         metaKeywords
         opengraphDescription
         opengraphImage {
           uri
           localFile {
             publicURL
           }
         }
         opengraphSiteName
         opengraphTitle
         opengraphType
         title
         twitterDescription
         twitterImage {
           uri
           localFile {
             publicURL
           }
         }
         twitterTitle
       }
     }
   }
 `

import React from 'react';

const Pagination = ({ url, current, total, pages }) => {
  if (pages === 1) {
    return <div className="pagination" />
  }

  const pageLinks = (paginationPages, currentPage, pageUrl) => {
    const content = []
    for (let i = 1; i <= paginationPages; i++) {
      if (currentPage === i) {
        content.push(
          <li className="pagination-page-link" key={`page-link-${i}`}>
            <span>{i}</span>
          </li>
        )
      } else {
        content.push(
          <li className="pagination-page-link" key={`page-link-${i}`}>
            <a href={`/${pageUrl}${i > 1 ? `/${i}` : ''}`}>{i}</a>
          </li>
        )
      }
    }
    return content
  }

  return (
    <div className="pagination">
      <ul>
        <li className="pagination-prev-link">
          {current < 2 && <span>Prev</span>}
          {current === 2 && <a href={`/${url}`}>Prev</a>}
          {current > 2 && <a href={`/${url}/${current - 1}`}>Prev</a>}
        </li>
        {pageLinks(pages, current, url)}
        <li className="pagination-next-link">
          {current >= pages && <span>Next</span>}
          {current < pages && <a href={`/${url}/${current + 1}`}>Next</a>}
        </li>
      </ul>
    </div>
  )
}

export default Pagination
